/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductState {
    total_product_sales: number;
    total_cashout: number;
    available_balance: number;
    product_purchased: any;
    product_sales: any;
    cashout_history: any;
    redeem_add_rate: number;
}

const initialState: ProductState = {
    total_product_sales: 0,
    total_cashout: 0,
    available_balance: 0,
    product_purchased: null,
    product_sales: null,
    cashout_history: null,
    redeem_add_rate: 0,
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        saveProduct(
            state,
            action: PayloadAction<{
                data: {
                    total_product_sales: number;
                    total_cashout: number;
                    available_balance: number;
                    product_purchased: any;
                    product_sales: any;
                    cashout_history: any;
                    redeem_add_rate: number;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_product_sales = data.total_product_sales;
            state.total_cashout = data.total_cashout;
            state.available_balance = data.available_balance;
            state.product_purchased = data.product_purchased;
            state.product_sales = data.product_sales;
            state.cashout_history = data.cashout_history;
            state.redeem_add_rate = data.redeem_add_rate;
        },
    },
});

export const { saveProduct } = productSlice.actions;
export default productSlice.reducer;
