/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
    pairing_bonus: number;
    referral_bonus: number;
    cashout: number;
    available_balance: number;
    incentives_balance: number;
    sales_history: any;
    transaction_history: any;
}

const initialState: DashboardState = {
    pairing_bonus: 0,
    referral_bonus: 0,
    cashout: 0,
    available_balance: 0,
    incentives_balance: 0,
    sales_history: null,
    transaction_history: null,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        saveDashboard(
            state,
            action: PayloadAction<{
                data: {
                    pairing_bonus: number;
                    referral_bonus: number;
                    cashout: number;
                    available_balance: number;
                    sales_history: any;
                    transaction_history: any;
                    incentives_balance: any;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.pairing_bonus = data.pairing_bonus;
            state.referral_bonus = data.referral_bonus;
            state.cashout = data.cashout;
            state.available_balance = data.available_balance;
            state.incentives_balance = data.incentives_balance;
            state.sales_history = data.sales_history;
            state.transaction_history = data.transaction_history;
        },
    },
});

export const { saveDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
