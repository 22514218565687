/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CommissionState {
    pairing_bonus: number;
    referral_bonus: number;
    cashout: number;
    available_balance: number;
    incentives_balance: number;
    account_history: any;
    referral_history: any;
    transaction_history: any;
    incentives_history: any;
    binary_fund: number;
}

const initialState: CommissionState = {
    pairing_bonus: 0,
    referral_bonus: 0,
    cashout: 0,
    available_balance: 0,
    incentives_balance: 0,
    account_history: null,
    referral_history: null,
    transaction_history: null,
    incentives_history: null,
    binary_fund: 0,
};

const commissionSlice = createSlice({
    name: "commission",
    initialState,
    reducers: {
        saveCommission(
            state,
            action: PayloadAction<{
                data: {
                    pairing_bonus: number;
                    referral_bonus: number;
                    cashout: number;
                    available_balance: number;
                    incentives_balance: number;
                    account_history: any;
                    referral_history: any;
                    transaction_history: any;
                    incentives_history: any;
                    binary_fund: number;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.pairing_bonus = data.pairing_bonus;
            state.referral_bonus = data.referral_bonus;
            state.cashout = data.cashout;
            state.available_balance = data.available_balance;
            state.incentives_balance = data.incentives_balance;
            state.account_history = data.account_history;
            state.referral_history = data.referral_history;
            state.transaction_history = data.transaction_history;
            state.incentives_history = data.incentives_history;
            state.binary_fund = data.binary_fund;
        },
    },
});

export const { saveCommission } = commissionSlice.actions;
export default commissionSlice.reducer;
