/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GenealogyState {
    acccount_package: string;
    pairing_bonus: number;
    waiting_left: number;
    waiting_right: number;
    genealogy: any;
    activation_pin: any;
    incentives: {
        type: string;
        max_pair: number;
        macthed: number;
        left_segment: number;
        right_segment: number;
    };
}

const initialState: GenealogyState = {
    acccount_package: "",
    pairing_bonus: 0,
    waiting_left: 0,
    waiting_right: 0,
    genealogy: null,
    activation_pin: null,
    incentives: {
        type: "",
        max_pair: 0,
        macthed: 0,
        left_segment: 0,
        right_segment: 0,
    },
};

const genealogySlice = createSlice({
    name: "genealogy",
    initialState,
    reducers: {
        saveGenealogy(
            state,
            action: PayloadAction<{
                data: {
                    acccount_package: string;
                    pairing_bonus: number;
                    waiting_left: number;
                    waiting_right: number;
                    genealogy: any;
                    activation_pin: any;
                    incentives: any;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.acccount_package = data.acccount_package;
            state.pairing_bonus = data.pairing_bonus;
            state.waiting_left = data.waiting_left;
            state.waiting_right = data.waiting_right;
            state.genealogy = data.genealogy;
            state.activation_pin = data.activation_pin;
            state.incentives.max_pair = data.incentives.max_pair;
            state.incentives.macthed = data.incentives.macthed;
            state.incentives.left_segment = data.incentives.left_segment;
            state.incentives.right_segment = data.incentives.right_segment;
            state.incentives.type = data.incentives.type;
        },
        setGenealogy(
            state,
            action: PayloadAction<{
                data: any;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.genealogy = data;
        },
    },
});

export const { saveGenealogy, setGenealogy } = genealogySlice.actions;
export default genealogySlice.reducer;
